import React, { useContext, useState } from "react"
import { motion } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"

import Button from "../../components/Button/Button"
import DesktopCartRow from "../DesktopCartRow/DesktopCartRow"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import { getSalePrice } from "../../utils/getSalePrice"
import { getDiscountPrice } from "../../utils/getDiscountPrice"

import "./desktop-cart.css"

const DesktopCart = () => {
  // Hold cart state
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  // Query for all discount codes
  const { allDiscountsJson } = useStaticQuery(
    graphql`
      query DiscountCodesDesktop {
        allDiscountsJson {
          edges {
            node {
              discountCodes {
                discount {
                  code
                  value
                }
              }
            }
          }
        }
      }
    `
  )
  const discountCodes = allDiscountsJson.edges[0].node.discountCodes

  // Hold state for discount inputs
  const [discount, setDiscount] = useState("")
  const [discountValue, setDiscountValue] = useState(0)
  const [discountError, setDiscountError] = useState(false)
  const [discountSuccess, setDiscountSuccess] = useState(false)

  // Handle adding discount code
  const handleAddDiscount = () => {
    const validDiscountCode = discountCodes.find(
      discountCode => discountCode.discount.code === discount
    )

    // Hold state for error or success of discount code
    if (validDiscountCode === undefined) {
      setDiscountError(true)
      setDiscountSuccess(false)
    } else {
      setDiscountSuccess(true)
      setDiscountError(false)
      setDiscountValue(validDiscountCode.discount.value)
      dispatch({
        type: "ADD_DISCOUNT",
        payload: {
          discount: validDiscountCode.discount.value,
        },
      })
    }
  }

  // Handle removing discount code
  const handleRemoveDiscount = () => {
    setDiscountError(false)
    setDiscountSuccess(false)
    setDiscount("")
    dispatch({
      type: "REMOVE_DISCOUNT",
    })
  }

  // Reduce total cart price
  let totalPrice = state.cart.reduce((prev, curr) => {
    const isOnSale = curr.product.productSale > 0

    if (isOnSale) {
      return (
        prev + getSalePrice(curr.product.productPrice, curr.product.productSale)
      )
    }

    return prev + curr.product.productPrice
  }, 0)

  return (
    <motion.div id={"shopping-cart-container"}>
      <motion.div id={"cart-header"}>
        <motion.h1 className={"header uppercase"} id={"cart-header-text"}>
          SHOPPING CART
        </motion.h1>
      </motion.div>
      <motion.div id={"cart-title-filler"}></motion.div>
      <motion.div id={"cart-title-item"}>
        <motion.p className={"paragraph uppercase"}>ITEM</motion.p>
      </motion.div>
      <motion.div id={"cart-title-price"}>
        <motion.p className={"paragraph uppercase"}>PRICE</motion.p>
      </motion.div>
      <motion.div id={"cart-title-remove"}>
        <motion.p className={"paragraph uppercase"}>REMOVE</motion.p>
      </motion.div>

      <motion.div id={"cart-product-container"}>
        {state.cart.map(({ product }, index) => {
          return <DesktopCartRow product={product} index={index} key={index} />
        })}
      </motion.div>

      <motion.div id={"cart-corner"}></motion.div>

      <motion.div id={"cart-price-info"}>
        <motion.p className={"paragraph uppercase"}>TOTAL</motion.p>
        <motion.p className={"paragraph uppercase"}>SHIPPING ESTIMATE</motion.p>
        <motion.p className={"paragraph uppercase"}>DUTIES AND TAXES</motion.p>
        <motion.p className={"paragraph uppercase"}>DISCOUNT CODE</motion.p>
        <motion.p className={"paragraph uppercase"}>ORDER TOTAL</motion.p>
      </motion.div>

      <motion.div id={"cart-price-totals"}>
        <motion.p className={"paragraph uppercase bold"}>
          €{totalPrice}
        </motion.p>
        <motion.p className={"paragraph uppercase"}>
          CALCULATED AT CHECKOUT
        </motion.p>
        <motion.p className={"paragraph uppercase"}>INCLUDED</motion.p>
        <motion.div id={"discount-input-container"}>
          {discountError ? (
            <motion.p id={"discount-code-error"}>INVALID CODE</motion.p>
          ) : null}
          {discountSuccess ? (
            <motion.p id={"discount-code-success"}>
              {discountValue}% DISCOUNT APPLIED
            </motion.p>
          ) : null}
          {!discountSuccess ? (
            <>
              <motion.input
                id={"discount-input"}
                placeholder={"ADD DISCOUNT CODE"}
                onChange={e => {
                  setDiscount(e.target.value.toUpperCase())
                }}
              />
              <motion.p id={"add-discount-button"} onClick={handleAddDiscount}>
                +
              </motion.p>{" "}
            </>
          ) : (
            <>
              <motion.p id={"discount-success-code"}>{discount}</motion.p>
              <motion.p
                id={"remove-discount-button"}
                onClick={handleRemoveDiscount}
              >
                X
              </motion.p>{" "}
            </>
          )}
        </motion.div>

        <motion.div id={"price-container"}>
          <motion.p
            className={`paragraph uppercase bold ${
              discountSuccess && "discount"
            }`}
          >
            €{totalPrice}
          </motion.p>
          {discountSuccess ? (
            <motion.p
              id={"discount-price"}
              className={"paragraph uppercase bold"}
            >
              €{getDiscountPrice(totalPrice, discountValue)}
            </motion.p>
          ) : null}
        </motion.div>
      </motion.div>

      <motion.div id={"cart-checkout-button"}>
        <Button
          text={"PROCEED TO CHECKOUT"}
          to={"/checkout"}
          className={"cart-checkout-button"}
        />
      </motion.div>
    </motion.div>
  )
}

export default DesktopCart
