import React, { useContext, useState } from "react"
import { motion } from "framer-motion"
import { Link, useStaticQuery, graphql } from "gatsby"

import MobileCartRow from "../MobileCartRow/MobileCartRow"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import { getSalePrice } from "../../utils/getSalePrice"
import { getDiscountPrice } from "../../utils/getDiscountPrice"

import x from "../../svg/x-black.svg"

import "./mobile-cart.css"

const MobileCart = () => {
  // Hold cart state
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  // Query for all discount codes
  const { allDiscountsJson } = useStaticQuery(
    graphql`
      query DiscountCodeMobile {
        allDiscountsJson {
          edges {
            node {
              discountCodes {
                discount {
                  code
                  value
                }
              }
            }
          }
        }
      }
    `
  )
  const discountCodes = allDiscountsJson.edges[0].node.discountCodes

  // Hold state for discount inputs
  const [discount, setDiscount] = useState("")
  const [discountValue, setDiscountValue] = useState(0)
  const [discountError, setDiscountError] = useState(false)
  const [discountSuccess, setDiscountSuccess] = useState(false)

  // Handle adding discount code
  const handleAddDiscount = () => {
    const validDiscountCode = discountCodes.find(
      discountCode => discountCode.discount.code === discount
    )

    // Hold state for error or success of discount code
    if (validDiscountCode === undefined) {
      setDiscountError(true)
      setDiscountSuccess(false)
    } else {
      setDiscountSuccess(true)
      setDiscountError(false)
      setDiscountValue(validDiscountCode.discount.value)
      dispatch({
        type: "ADD_DISCOUNT",
        payload: {
          discount: validDiscountCode.discount.value,
        },
      })
    }
  }

  // Handle removing discount code
  const handleRemoveDiscount = () => {
    setDiscountError(false)
    setDiscountSuccess(false)
    setDiscount("")
    dispatch({
      type: "REMOVE_DISCOUNT",
    })
  }

  // Reduce total cart price
  let totalPrice = state.cart.reduce((prev, curr) => {
    const isOnSale = curr.product.productSale > 0

    if (isOnSale) {
      return (
        prev + getSalePrice(curr.product.productPrice, curr.product.productSale)
      )
    }

    return prev + curr.product.productPrice
  }, 0)

  return (
    <motion.div id={"shopping-cart-container"}>
      <motion.div id={"cart-header"}>
        <motion.h1 className={"header uppercase cart-header"}>
          SHOPPING CART
        </motion.h1>
      </motion.div>
      <Link to={"/"} id={"close-icon-container"}>
        <motion.img src={x} alt={"Close Icon"} id={"close-icon"}></motion.img>
      </Link>
      <motion.div id={"cart-product-container"}>
        {state.cart.map(({ product }, index) => {
          return <MobileCartRow product={product} index={index} key={index} />
        })}
      </motion.div>

      <motion.div id={"cart-total-description"}>
        <motion.p className={"paragraph uppercase"}>TOTAL</motion.p>
        <motion.p className={"paragraph uppercase"}>SHIPPING</motion.p>
        <motion.p className={"paragraph uppercase"}>DUTIES/TAXES</motion.p>
        <motion.p className={"paragraph uppercase"}>DISCOUNT</motion.p>
        <motion.p className={"paragraph uppercase"}>ORDER TOTAL</motion.p>
      </motion.div>

      <motion.div id={"cart-total-info"}>
        <motion.p className={"paragraph uppercase"}>
          CALCULATED AT CHECKOUT
        </motion.p>
        <motion.p className={"paragraph uppercase"}>INCLUDED</motion.p>
        <motion.div id={"discount-input-container"}>
          {!discountSuccess ? (
            <>
              <motion.input
                id={"discount-input"}
                className={discountError ? "discount-error" : undefined}
                placeholder={"ADD DISCOUNT CODE"}
                onChange={e => {
                  setDiscount(e.target.value.toUpperCase())
                }}
              />
              <motion.p id={"add-discount-button"} onClick={handleAddDiscount}>
                +
              </motion.p>{" "}
            </>
          ) : (
            <>
              <motion.p
                id={"discount-success-code"}
                className={discountSuccess ? "discount-success" : undefined}
              >
                {discount}
              </motion.p>
              <motion.p
                id={"remove-discount-button"}
                onClick={handleRemoveDiscount}
              >
                X
              </motion.p>{" "}
            </>
          )}
        </motion.div>
      </motion.div>

      <motion.div id={"cart-total-price"}>
        <motion.p className={"paragraph uppercase bold"}>
          €{totalPrice}
        </motion.p>
        <motion.div style={{ height: 34 }} id={"spacer"}></motion.div>
        <motion.p
          className={`paragraph uppercase bold ${
            discountSuccess && "discount"
          }`}
        >
          €{totalPrice}
        </motion.p>
        {discountSuccess ? (
          <motion.p
            id={"discount-price"}
            className={"paragraph uppercase bold"}
          >
            €{getDiscountPrice(totalPrice, discountValue)}
          </motion.p>
        ) : null}
      </motion.div>
    </motion.div>
  )
}

export default MobileCart
