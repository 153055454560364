import React, { useContext } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import Img from "gatsby-image"

import productPathUtil from "../../utils/productPath"
import { getSalePrice } from "../../utils/getSalePrice"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

import x from "../../svg/x-black.svg"

import "./desktop-cart-row.css"

const DesktopCartRow = ({ product, index }) => {
  const dispatch = useContext(GlobalDispatchContext)

  const isOnSale = product.productSale > 0
  const salePrice = getSalePrice(product.productPrice, product.productSale)

  const handleRemoveItem = () => {
    dispatch({
      type: "REMOVE_ITEM",
      payload: { index: index },
    })
  }

  return (
    <motion.div id={"cart-product-row"}>
      <motion.div id={"cart-product-image"}>
        <Link to={`/${productPathUtil(product.productName)}`}>
          <Img
            fluid={product.productImages.image1.childImageSharp.fluid}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </Link>
      </motion.div>

      <motion.div id={"cart-product-info"}>
        <motion.p className={"paragraph uppercase"}>
          {product.productName}
        </motion.p>
        <motion.p className={"paragraph uppercase"}>
          {product.productDescription}
        </motion.p>
        <motion.p className={"paragraph uppercase"}>
          COLOR:{" "}
          {product.productData.productColor.map((color, index) => (
            <motion.span key={index}>
              {color}
              {index !== product.productData.productColor.length - 1 && ", "}
            </motion.span>
          ))}
        </motion.p>
        <motion.p className={"paragraph uppercase"}>
          BODY: {product.productData.productMaterial}.{" "}
          {product.productData.productProductionCountry &&
            `MADE IN
          ${product.productData.productProductionCountry}
        `}
        </motion.p>
      </motion.div>

      <motion.div id={"cart-product-price"}>
        <motion.p className={`paragraph uppercase ${isOnSale && "sale"}`}>
          €{product.productPrice}
        </motion.p>
        {isOnSale ? (
          <motion.p className={"paragraph uppercase cart-sale-price"}>
            SALE €{salePrice}
          </motion.p>
        ) : null}
      </motion.div>

      <motion.div id={"cart-product-remove"} onClick={() => handleRemoveItem()}>
        <motion.img src={x} alt={"Remove Icon"}></motion.img>
      </motion.div>
    </motion.div>
  )
}

export default DesktopCartRow
