import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import DesktopCart from "../components/DesktopCart/DesktopCart"
import MobileCart from "../components/MobileCart/MobileCart"
import DesktopFooter from "../components/DesktopFooter/DesktopFooter"
import MobileFooter from "../components/MobileFooter/MobileFooter"

import { useWindowSize } from "../utils/useWindowSize"

const CartPage = () => {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Cart" />
      {width > 1278 ? (
        <>
          <DesktopHeader /> <DesktopCart /> <DesktopFooter />
        </>
      ) : (
        <>
          <MobileCart />
          <MobileFooter />
        </>
      )}
    </Layout>
  )
}

export default CartPage
