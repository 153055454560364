// @TODO update this to catch all non-common patterns

function productPathUtil(productName) {
  return productName
    .replace(/ /g, "-")
    .replace("#", "")
    .replace(",", "")
    .replace(".", "")
    .replace("'", "")
    .replace("%", "")
    .toLowerCase()
}

module.exports = productPathUtil
